<template>
  <div class="columns pt-5 px-5">
    <div class="column">
      <div class="block title question">
        <div class="report-title">
          {{ tableName }}
        </div>

        <div class="btn-export-container">
          <b-button
            class="new-survey-btn"
            icon-right="file-export-outline"
            rounded
            @click="exportCsv()"
            v-if="permissionValidation(permission.downloadPermission)"
            :loading="exportCsvLoading"
            >Exportar</b-button
          >
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Encuesta">
            <b-select
              placeholder="Seleccionar encuesta"
              expanded
              v-model="surveyCode"
            >
              <option
                v-for="(option, index) in surveys"
                :value="option.code"
                :key="index"
              >
                {{ option.name }} - {{ option.code }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha de inicio">
            <b-datepicker
              v-model="startDate"
              ref="startDate"
              value="startDate"
              expanded
              placeholder="Fecha de inicio"
            >
            </b-datepicker>
            <b-button
              @click="$refs.startDate.toggle()"
              icon-left="calendar-today"
              type="is-info"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Fecha final">
            <b-datepicker
              v-model="finalDate"
              :min-date="startDate"
              ref="endDate"
              expanded
              value="finalDate"
              placeholder="Fecha final"
            >
            </b-datepicker>
            <b-button
              @click="$refs.endDate.toggle()"
              icon-left="calendar-today"
              type="is-info"
            />

            <b-button
              type="is-info"
              class="refresh-button ml-5"
              icon-right="calendar-search"
              @click="reloadReports"
            />
          </b-field>
        </div>
      </div>

      <b-table
        :data="data"
        :bordered="false"
        :striped="false"
        :hoverable="true"
        :mobile-cards="true"
        :paginated="true"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :backend-pagination="true"
        :total="total"
        :sticky-header="true"
        :pagination-rounded="true"
        type="is-info"
        @page-change="makeSearch"
        class="mb-5"
      >
        <b-table-column field="patientId" label="Paciente" v-slot="props">
          {{ props.row.patientId }}
        </b-table-column>

        <b-table-column field="date" label="Fecha de encuesta" v-slot="props">
          {{ props.row.date | myDate }}
        </b-table-column>

        <b-table-column
          field="result"
          label="Resultado"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ props.row.result ? props.row.result : "No disponible" }}
        </b-table-column>

        <b-table-column
          field="appreciation"
          label="Apreciación"
          v-slot="props"
          :td-attrs="columnTdAttrs"
        >
          {{
            props.row.appreciation ? props.row.appreciation : "No disponible"
          }}
        </b-table-column>

        <b-table-column label="Acciones" v-slot="props">
          <b-button
            class="new-survey-btn"
            rounded
            @click="goToSurvey(props.row)"
            >Ir a encuesta</b-button
          >
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">Sin resultados</div>
        </template>

        <template #bottom-left>
          <b-select v-model="perPage">
            <option
              v-for="(item, index) in itemsPerPage"
              :key="index"
              :value="item"
              >{{ `${item} por página` }}</option
            >
          </b-select>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import DETAIL_PATHS from "../../enum/detailPaths";

export default {
  props: {
    tableName: {
      type: String,
      default: "",
    },
    isDetailedReport: {
      type: Boolean,
    },
    permission: {
      type: Object,
      required: false,
      default: function() {
        return {
          downloadPermission: null,
        };
      },
    },
  },
  data() {
    return {
      data: [],
      total: 0,
      isEmpty: false,
      surveyCode: null,
      perPage: 10,
      itemsPerPage: [10, 20, 30, 40, 50],
      currentPage: 1,
      startDate: null,
      finalDate: null,
      surveys: [],
      exportCsvLoading: false,
    };
  },
  created() {
    this.$global.$on("patientDataDeleted", () => {
      this.getReports();
    });

    this.$global.$on("patientLoaded", () => {
      this.getReports();
    });

    this.getSurvey();
  },
  computed: {
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  watch: {
    perPage: function() {
      this.currentPage = 1;
      this.getReports();
    },
  },
  methods: {
    async getSurvey() {
      const response = await this.$clinimetriasService.getSurveys();
      this.surveys = response.data;
      this.surveyCode = response.data[0]?.code;
      this.getReports();
    },
    goToSurvey(router = {}) {
      this.$router.push({
        name: `detail-${DETAIL_PATHS[router.surveyCode]}`,
        params: { surveyId: router.id },
      });

      this.$global.$emit("setPatientInput", router.patientId);
    },
    reloadReports() {
      this.getReports();
      //this.startDate = null;
      //this.finalDate = null;
    },
    async getReports() {
      this.changeLoaderStatus(true);
      try {
        if (
          moment(this.finalDate).format("YYYY-MM-DD") <
          moment(this.startDate).format("YYYY-MM-DD")
        ) {
          this.showToast(
            "Las fecha final no debe ser mayor a la fecha de inicio.",
            "is-warning",
            3000
          );
          this.changeLoaderStatus(false);
          return;
        }

        this.$global.$emit(
          "setPatientInput",
          this.patient ? this.patient?.document : null
        );
        const response = await this.$reportRepository.getSurveys(
          this.currentPage,
          this.perPage,
          this.startDate
            ? moment(this.startDate).format("YYYY-MM-DD 00:00:00")
            : null,
          this.finalDate
            ? moment(this.finalDate).format("YYYY-MM-DD 23:59:59")
            : null,
          this.surveyCode ? this.surveyCode : null,
          this.patient ? this.patient.document : null
        );

        this.data = response.data.data;
        this.total = response.data.total;
        this.changeLoaderStatus(false);
      } catch (error) {
        console.log(error);
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    makeSearch(page) {
      this.currentPage = page;
      this.getReports();
    },
    columnTdAttrs(row, col) {
      if (col.field == "appreciation") {
        if (row.appreciation === null || row.appreciation === "") {
          return {
            class: "no-avaliable-field",
          };
        }
      } else if (col.field == "result") {
        if (row.result === null || row.result === "") {
          return {
            class: "no-avaliable-field",
          };
        }
      }
    },
    async getDataExport() {
      let response;
      if (this.isDetailedReport) {
        response = await this.$reportRepository.detailed(
          this.startDate
            ? moment(this.startDate).format("YYYY-MM-DD 00:00:00")
            : moment(new Date()).format("YYYY-MM-DD 00:00:00"),
          this.finalDate
            ? moment(this.finalDate).format("YYYY-MM-DD 23:59:59")
            : moment(new Date()).format("YYYY-MM-DD 23:59:59"),
          this.surveyCode ? this.surveyCode : null
        );
      } else {
        response = await this.$reportRepository.simple(
          this.startDate
            ? moment(this.startDate).format("YYYY-MM-DD 00:00:00")
            : moment(new Date()).format("YYYY-MM-DD 00:00:00"),
          this.finalDate
            ? moment(this.finalDate).format("YYYY-MM-DD 23:59:59")
            : moment(new Date()).format("YYYY-MM-DD 23:59:59"),
          this.surveyCode ? this.surveyCode : null
        );
      }
      return response;
    },
    async exportCsv() {
      this.exportCsvLoading = true;
      if (!this.startDate || !this.finalDate) {
        this.showToast(
          "Por favor seleccione un rango de fechas",
          "is-warning",
          3000
        );
        return;
      }

      const { data } = await this.getDataExport();
      const pollException = ["NAPSIMM", "NAPSI", "PASI", "EASI", "UAS7"];

      const questionCodeTraduction = {
        HEAD: "Cabeza y cuello",
        TRUK: "Tronco",
        LOWER: "Extremidades inferiores",
      };

      const excelData = data.data.map((record) => {
        const detail = record.evaluationDetails?.reduce(
          (previusValue, currentValue) => {
            if (currentValue.question && currentValue.answer) {
              const questionName = currentValue.question.name;
              if (pollException.includes(this.surveyCode)) {
                let questionCode = currentValue.question.code?.split("_");
                let code = questionCodeTraduction[questionCode[1]]
                  ? questionCodeTraduction[questionCode[1]]
                  : questionCode[1];
                previusValue[
                  `${questionName} ${code} ${
                    this.surveyCode === "UAS7" ? questionCode[2] : ""
                  }`
                ] = currentValue.answer.name;
              } else {
                previusValue[`${questionName}`] = currentValue.answer.name;
              }
            } else if (currentValue.question) {
              const questionName = currentValue.question.name;
              previusValue[questionName] = currentValue.value;
            }
            return previusValue;
          },
          {}
        );

        if (this.isDetailedReport) {
          return {
            Paciente: record.patientId,
            "Fecha de encuesta": moment(record.date).format("YYYY-MM-DD"),
            Resultado: record.result,
            "Código de encuesta": record.surveyCode,
            Apreciación: record.appreciation
              ? record.appreciation
              : "No disponible",
            ...detail,
          };
        }

        return {
          Paciente: record.patientId,
          "Fecha de encuesta": moment(record.date).format("YYYY-MM-DD"),
          Resultado: record.result,
          "Código de encuesta": record.surveyCode,
          Apreciación: record.appreciation
            ? record.appreciation
            : "No disponible",
        };
      });

      const options = {
        fieldSeparator: ";",
        filename: this.isDetailedReport
          ? "Reporte detallado " + moment(new Date()).format("YYYY_MM_DD")
          : "Reporte simple " + moment(new Date()).format("YYYY_MM_DD"),
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: headers,
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(excelData);
      this.exportCsvLoading = false;
    },
    permissionValidation(permission) {
      return this.$ability.can(permission);
    },
    validatePatient() {},
  },
};
</script>

<style scoped>
.question {
  display: flex;
}

.report-title {
  width: 50%;
  text-align: start;
}
.btn-export-container {
  width: 50%;
  text-align: end;
}
</style>
